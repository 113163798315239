import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = {
  class: "stepper stepper-links d-flex flex-column",
  id: "kt_create_account_stepper",
  ref: "horizontalWizardRef"
}
const _hoisted_4 = {
  class: "current",
  "data-kt-stepper-element": "content"
}
const _hoisted_5 = { "data-kt-stepper-element": "content" }
const _hoisted_6 = { "data-kt-stepper-element": "content" }
const _hoisted_7 = { "data-kt-stepper-element": "content" }
const _hoisted_8 = { "data-kt-stepper-element": "content" }
const _hoisted_9 = { class: "d-flex flex-stack pt-15" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_12 = { class: "indicator-label" }
const _hoisted_13 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_14 = {
  key: 1,
  type: "submit",
  class: "btn btn-lg btn-primary"
}
const _hoisted_15 = { class: "svg-icon svg-icon-4 ms-1 me-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Step1 = _resolveComponent("Step1")!
  const _component_Step2 = _resolveComponent("Step2")!
  const _component_Step3 = _resolveComponent("Step3")!
  const _component_Step4 = _resolveComponent("Step4")!
  const _component_Step5 = _resolveComponent("Step5")!
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"stepper-nav py-5 mt-5\"><div class=\"stepper-item current\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Account Type</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Account Info</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Business Info</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Billing Details</h3></div><div class=\"stepper-item\" data-kt-stepper-element=\"nav\"><h3 class=\"stepper-title\">Completed</h3></div></div>", 1)),
        _createElementVNode("form", {
          class: "mx-auto mw-600px w-100 pt-15 pb-10",
          novalidate: "novalidate",
          id: "kt_create_account_form",
          onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleStep && _ctx.handleStep(...args)))
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Step1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Step2)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Step3)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Step4)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_Step5)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-lg btn-light-primary me-3",
                "data-kt-stepper-action": "previous",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousStep && _ctx.previousStep(...args)))
              }, [
                _createElementVNode("span", _hoisted_11, [
                  _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr063.svg" })
                ]),
                _cache[3] || (_cache[3] = _createTextVNode(" Back "))
              ])
            ]),
            _createElementVNode("div", null, [
              (_ctx.currentStepIndex === _ctx.totalSteps - 1)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-lg btn-primary me-3",
                    "data-kt-stepper-action": "submit",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.formSubmit()))
                  }, [
                    _createElementVNode("span", _hoisted_12, [
                      _cache[4] || (_cache[4] = _createTextVNode(" Submit ")),
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                      ])
                    ]),
                    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "indicator-progress" }, [
                      _createTextVNode(" Please wait... "),
                      _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                    ], -1))
                  ]))
                : (_openBlock(), _createElementBlock("button", _hoisted_14, [
                    _cache[6] || (_cache[6] = _createTextVNode(" Continue ")),
                    _createElementVNode("span", _hoisted_15, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                    ])
                  ]))
            ])
          ])
        ], 32)
      ], 512)
    ])
  ]))
}